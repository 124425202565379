import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";
import FakeContent from "../components/digitalTransformation/FakeContent";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading: FakeContent,
});
const Header = Loadable({
  loader: () => import("../components/digitalTransformation/Header"),
  loading: Loading,
});
const DigitalTransformation = Loadable({
  loader: () =>
    import("../components/digitalTransformation/DigitalTransformation"),
  loading: Loading,
});
const ConsultingServices = Loadable({
  loader: () =>
    import("../components/digitalTransformation/ConsultingServices"),
  loading: Loading,
});
const Competencies = Loadable({
  loader: () => import("../components/digitalTransformation/Competencies"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading: Loading,
});

export default () => {
  return (
    <Layout>
      <SEO
        title={"Digital Transformation | Datum Consulting NZ"}
        description={
          "Our Digital Transformation and Strategy services include " +
          "planning and execution for " +
          "maturity assessment, digital strategy, digital transformation, " +
          "agile transformation, big data & analytics, cloud computing, " +
          "internet of things (iot), cyber-security, augmented reality, " +
          "artificial intelligence (AI)."
        }
        keywords={[
          `maturity benchmark`,
          `digital strategy`,
          `software development`,
          `digital transformation`,
          `agile transformation`,
          `software development`,
          `enterprise support`,
          `it consulting`,
          `big data`,
          `analytics`,
          `internet of things`,
          `iot`,
          `cybersecurity`,
          `augmented reality`,
          `cloud computing`,
          `artificial intelligence`,
          `AI`,
        ]}
        pathname="digital-transformation"
      />
      <Nav />
      <Header />
      <DigitalTransformation />
      <ConsultingServices />
      <Competencies />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
