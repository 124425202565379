import React from "react";
import {SEO_TYPE} from "../../constant";
import SeoElement from "../SeoElement"

const data = [
    {
        type: SEO_TYPE.H1,
        heading: "Digital Transformation with Datum Consulting NZ"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "By combining proven and emerging technological advances such as " +
            "cloud computing, connected devices, " +
            "data driven services, and AI, an enormous number of business process " +
            "will be automated in the coming " +
            "decades. These very real technologies are already improving customer " +
            "engagement, adding lines of new " +
            "products and services, fueling companies to change and create entirely " +
            "new business models."
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Our strategy and implementation services will guide you through " +
            "your digital transformation journey " +
            "from then very start, setting out clear " +
            "objectives and achievable milestones."
    },
    {
        type: SEO_TYPE.H3,
        heading: "UNDERSTAND YOUR INDUSTRY"
    },
    {
        type: SEO_TYPE.H3,
        heading: "SEIZE THE OPPORTUNITY"
    },
    {
        type: SEO_TYPE.H3,
        heading: "NAVIGATE & INCUBATE"
    },
    {
        type: SEO_TYPE.H3,
        heading: "DIGITAL & CULTURAL CHANGES"
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/contact/",
        title: "DISCUSS DIGITAL STRATEGY WITH US",
        label: "DISCUSS DIGITAL STRATEGY WITH US"
    },
    // services offering
    {
        type: SEO_TYPE.H2,
        heading: "Our digital transformation consulting services"
    },
    // services offering: Digital Maturity benchmark
    {
        type: SEO_TYPE.H3,
        heading: "Digital Maturity benchmark"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Changes can be daunting, and to unlock your digital transformation, " +
            "you will need a clear vision of where you are starting from. Datum " +
            "Consulting will help you assess your organization digital maturity in " +
            "isolation and relative to other organizations in your industry. Our " +
            "assessment will help identify where the growth gaps are."
    },
    // services offering: Digital strategy
    {
        type: SEO_TYPE.H3,
        heading: "Digital strategy"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We can help you establish tailored, clear, actionable plan with " +
            "measurable metrics on how new digital capabilities can enhance " +
            "customer experience, innovate in products/services, and digitize " +
            "business processes. The digital strategy business case will provide " +
            "a clear investment focus for your strategic intent."
    },
    // services offering: Digital transformation
    {
        type: SEO_TYPE.H3,
        heading: "Digital transformation"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "With our digital transformation approach, we will guide your company " +
            "to break out of the vertical integration mentality, build internal " +
            "capabilities, and bring in full-fledged digital offering of latest " +
            "advances in technology to create new sources of value and " +
            "business model from within the core of your business."
    },
    // services offering: Agile Transformation
    {
        type: SEO_TYPE.H3,
        heading: "Agile Transformation"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Most enterprises are still operating in old ways, which negatively " +
            "affect innovation speed and adaptability. Large scale agile " +
            "transformation isn't just about technology but a collaborative, open, " +
            "and creative way of operating organizations. Datum Consulting can " +
            "embeds this digital ways of working into your company culture."
    },
    // competencies
    {
        type: SEO_TYPE.H3,
        heading: "Competencies and specialties"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Big Data & Advanced Analytics"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cloud Computing"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Internet Of Things"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Cyber-Security"
    },
    {
        type: SEO_TYPE.H3,
        heading: "Artificial Intelligence"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://linkedin.com/company/datumconsultingnz",
        title: "Linkedin",
        label: "Linkedin"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://twitter.com/datumhqNZ",
        title: "Twitter",
        label: "Twitter"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://www.facebook.com/DatumConsultingNZ",
        title: "Facebook",
        label: "Facebook"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://medium.com/datum-consulting-nz",
        title: "Medium",
        label: "Medium"
    }
];

const FakeContent = () => (
    <div
        style={{
            backgroundColor: "white",
            color: "transparent",
            height: "100vh",
            width: "100vw",
            position: "relative",
            top: "0",
            left: "0",
        }}
    >
        {data.map((element, index) => <SeoElement key={index} {...element}/>)}
    </div>
);

export default FakeContent;